import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Carousel, Cascader, Modal, Popconfirm, Empty, Pagination, Spin, message, Dropdown, Icon, Menu, Input, Button, Radio, Checkbox, Form, InputNumber, Popover } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.config.productionTip = false
Vue.use(Empty);
Vue.use(Pagination);
Vue.use(Spin);
Vue.use(Dropdown);
Vue.use(Icon);
Vue.use(Menu);
Vue.use(Input);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Form);
Vue.use(InputNumber);
Vue.use(Popover);
Vue.use(Radio);
Vue.use(Popconfirm);
Vue.use(Modal);
Vue.use(Cascader);
Vue.use(Carousel);
Vue.prototype.$message = message;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
